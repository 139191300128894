import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import PredictionInput from './PredictionInput';
import Prediction from './Prediction';


const styles = theme => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
    [theme.breakpoints.up(600 + theme.spacing.unit * 2 * 2)]: {
      width: 'auto',
      maxWidth: 1200,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    padding: theme.spacing.unit * 2,
    [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
      marginTop: theme.spacing.unit * 6,
      marginBottom: theme.spacing.unit * 6,
      padding: theme.spacing.unit * 3,
    },
  },
  stepper: {
    padding: `${theme.spacing.unit * 3}px 0 ${theme.spacing.unit * 5}px`,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing.unit * 3,
    marginLeft: theme.spacing.unit,
  },
});





class Info extends React.Component {

  
  constructor(props) {
    super(props);
    this.state = {
      observation: {
        suggested_price: '',
        number_of_rooms: '',
        livingm2: '',
        monthlyfee: '',
        latitude: '',
        longitude: '',
        date_sold: ''
      },
      prediction: {},
      activeStep: 0,
      buttondisabled: false
    };     
    this.handlePredictionInputChange = this.handlePredictionInputChange.bind(this);
    this.setLonLat = this.setLonLat.bind(this);
  }
handlePredictionInputChange(event) {
  const target = event.target;
  const value = target.type === 'checkbox' ? target.checked : target.value;
  const name = target.name;
     this.setState(prevState => ({
      observation: {
          ...prevState.observation,
          [name]: value
      }
  }));
}
setLonLat(coordinate){
  const lat = coordinate.latitude
  const lng = coordinate.longitude
 this.setState(prevState => ({
    observation: {
        ...prevState.observation,
        latitude: lat,
        longitude: lng
    }
}));
}

enablebutton() {
  const { livingm2, longitude,suggested_price,number_of_rooms,monthlyfee,latitude,date_sold } = this.state.observation;
  return (
    livingm2 > 0 &&
    longitude > 0 &&
    suggested_price > 0 &&
    number_of_rooms > 0 &&
    monthlyfee > 0 &&
    latitude > 0 &&
    date_sold.length > 0
  );
}

getStepContent(step) {
  switch (step) {
    case 0:
      return <PredictionInput onObservationUpdate={this.setObservation} handlePredictionInputChange={this.handlePredictionInputChange} setLonLat={this.setLonLat}/>;
    case 1:
      return <Prediction observation={this.state.observation}/>;
    default:
      throw new Error('Unknown step');
  }
}

  handleNext = () => {
    this.setState(state => ({
      activeStep: state.activeStep + 1,
    }));
  };

  handleBack = () => {
    this.setState(state => ({
      activeStep: state.activeStep - 1,
    }));
  };

  handleReset = () => {
    this.setState({
      activeStep: 0,
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <CssBaseline />
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <Typography component="h1" variant="h4" align="center">
              Välkommen till kolla bostadspris!
            </Typography>
            <Typography component="h1" variant="subtitle1" align="center">
              Verktyget förutsäger försäljningspriset för bostadsrätter i Göteborgsområdedet.

              Verktyget utnyttjar en maskininlärningsalgormitm som har analyserat tidigare försäljningar och kan därmed uppskatta slutpriset vid nya föräljningar.
              För att genomföra en slutprisförutsägning fyll i uppgifter för den aktuella bostaden nedan.
            </Typography>
          </Paper>
        </main>
      </React.Fragment>
    );
  }
}

Info.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Info);