import React from 'react';
import axios from 'axios';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

const APIURL = 'https://62e6jcufdh.execute-api.eu-north-1.amazonaws.com/dev/prediction';


class Prediction extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      prediction: {},
      predictiondone: false
    };
  }

  componentDidMount() {
    var observation = {
      "observation": {
        "suggested_price": this.props.observation.suggested_price,
        "number_of_rooms": this.props.observation.number_of_rooms,
        "livingm2": this.props.observation.livingm2,
        "monthlyfee": this.props.observation.monthlyfee,
        "latitude": this.props.observation.latitude,
        "longitude": this.props.observation.longitude,
        "date_sold": this.props.observation.date_sold
      }
    }
    axios.post(APIURL, observation)
      .then(res => {
        this.setState({ prediction: res.data });
        this.setState({predictiondone: true});

      })
  }

  render() {
    var prediction = this.state.prediction['prediction'];
    var roundedprediction = Math.round(prediction/1000)*1000;
    const formatter = new Intl.NumberFormat('sv-SV', {
      style: 'currency',
      currency: 'SEK'
    })
    var formatedprediction = formatter.format(roundedprediction)



    
    return (
      
        <React.Fragment>
          <Typography variant="h6" gutterBottom align='center' >
            Slutprisuppskattning
      </Typography>
          <Grid container spacing={24}>
            <Grid item xs={12} md={12}>
              <Typography variant="h6" gutterBottom>
                Bostaden förväntas säljas för {this.state.predictiondone && formatedprediction }
                {!this.state.predictiondone && <CircularProgress/> }
              </Typography>
       
            </Grid>
          </Grid>
        </React.Fragment>
        );
      }
    }
export default Prediction;