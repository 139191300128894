import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import PredictionInput from './PredictionInput';
import Prediction from './Prediction';


const styles = theme => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
    [theme.breakpoints.up(600 + theme.spacing.unit * 2 * 2)]: {
      width: 'auto',
      maxWidth: 1200,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    padding: theme.spacing.unit * 2,
    [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
      marginTop: theme.spacing.unit * 6,
      marginBottom: theme.spacing.unit * 6,
      padding: theme.spacing.unit * 3,
    },
  },
  stepper: {
    padding: `${theme.spacing.unit * 3}px 0 ${theme.spacing.unit * 5}px`,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing.unit * 3,
    marginLeft: theme.spacing.unit,
  },
});

//const steps = ['Bostad Uppgifer', 'Uppskattat försäljningspris', 'Bli kontaktad'];
const steps = ['Bostad Uppgifer', 'Uppskattat försäljningspris'];



class MainFlow extends React.Component {

  
  constructor(props) {
    super(props);
    this.state = {
      observation: {
        suggested_price: '',
        number_of_rooms: '',
        livingm2: '',
        monthlyfee: '',
        latitude: '',
        longitude: '',
        date_sold: ''
      },
      prediction: {},
      activeStep: 0,
      buttondisabled: false
    };     
    this.handlePredictionInputChange = this.handlePredictionInputChange.bind(this);
    this.setLonLat = this.setLonLat.bind(this);
  }
handlePredictionInputChange(event) {
  const target = event.target;
  const value = target.type === 'checkbox' ? target.checked : target.value;
  const name = target.name;
     this.setState(prevState => ({
      observation: {
          ...prevState.observation,
          [name]: value
      }
  }));
}
setLonLat(coordinate){
  const lat = coordinate.latitude
  const lng = coordinate.longitude
 this.setState(prevState => ({
    observation: {
        ...prevState.observation,
        latitude: lat,
        longitude: lng
    }
}));
}

enablebutton() {
  const { livingm2, longitude,suggested_price,number_of_rooms,monthlyfee,latitude,date_sold } = this.state.observation;
  return (
    livingm2 > 0 &&
    longitude > 0 &&
    suggested_price > 0 &&
    number_of_rooms > 0 &&
    monthlyfee > 0 &&
    latitude > 0 &&
    date_sold.length > 0
  );
}

getStepContent(step) {
  switch (step) {
    case 0:
      return <PredictionInput observation={this.state.observation} onObservationUpdate={this.setObservation} handlePredictionInputChange={this.handlePredictionInputChange} setLonLat={this.setLonLat}/>;
    case 1:
      return <Prediction observation={this.state.observation}/>;
    default:
      throw new Error('Unknown step');
  }
}

  handleNext = () => {
    this.setState(state => ({
      activeStep: state.activeStep + 1,
    }));
  };

  handleBack = () => {
    this.setState(state => ({
      activeStep: state.activeStep - 1,
    }));
  };

  handleReset = () => {
    this.setState({
      activeStep: 0,
    });
  };

  render() {
    const { classes } = this.props;
    const { activeStep } = this.state;
    const enablebutton = this.enablebutton();

    return (
      <React.Fragment>
        <CssBaseline />
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <Typography component="h1" variant="h4" align="center">
            Slutprisförutsägning
            </Typography>
            <Stepper activeStep={activeStep} className={classes.stepper}>
              {steps.map(label => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <React.Fragment>
              {activeStep === steps.length ? (
                <React.Fragment>
                  <Typography variant="h5" gutterBottom>
                    Thank you for your order.
                  </Typography>
                  <Typography variant="subtitle1">
                    Your order number is #2001539. We have emailed your order confirmation, and will
                    send you an update when your order has shipped.
                  </Typography>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  
                  {this.getStepContent(activeStep)}
                  <div className={classes.buttons}>
                    {activeStep !== 0 && (
                      <Button onClick={this.handleBack} className={classes.button}>
                        Back
                      </Button>
                    )}
                    {activeStep === 0 &&
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.handleNext}
                      className={classes.button}
                      disabled={!enablebutton}
                    >
                      {activeStep === steps.length - 1 ? 'Place order' : 'Förutsägning'}
                    </Button>
                    }
                  </div>
                </React.Fragment>
              )}
            </React.Fragment>
          </Paper>
        </main>
      </React.Fragment>
    );
  }
}

MainFlow.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MainFlow);