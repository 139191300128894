import React, { Component } from 'react'
import NavBar from './components/NavBar'
import MainFlow from './components/MainFlow'
import Info from './components/Info'
import Grid from '@material-ui/core/Grid';


//App theme imports
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';


  
 

const Muitheme = createMuiTheme({
  palette: {
    primary: {
      light: '#439889',
      main: '#00695c',
      dark: '#003d33',
      contrastText: '#fff',
    },
    secondary: {
      light: '#62717b',
      main: '#37464f',
      dark: '#101f27',
      contrastText: '#000',
    },
  },
  typography: {
    useNextVariants: true,
  },
}
);

class App extends Component {
  componentDidMount(){
   
  }
  render() {
    return (
<MuiThemeProvider theme={Muitheme}>
      <div >
        
          <NavBar />
          <Grid container direction="column" justify="center"alignItems="center" spacing={24}>
            <Grid item xs={12}>
              <Info></Info>
            </Grid>
            <Grid item xs={12}>
              <MainFlow></MainFlow>
            </Grid>
          </Grid>
        
      </div>
      </MuiThemeProvider>
    )
  }


  handleScriptLoad() {

    // Declare Options For Autocomplete 
    var options = { types: ['(cities)'] };

    // Initialize Google Autocomplete 
    /*global google*/
    this.autocomplete = new google.maps.places.Autocomplete(
      document.getElementById('autocomplete'),
      options);

    // Fire Event when a suggested name is selected
    this.autocomplete.addListener('place_changed',
      this.handlePlaceSelect);
  }


}

export default App