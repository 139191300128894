// api key = AIzaSyA1h8qnNHfImevvuRqNorH0xYY1zV4wC6Q

// Imports
import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';

// Import React Scrit Libraray to load Google object
import Script from 'react-load-script';

class AddressSearch extends Component {
  // Define Constructor
  constructor(props) {
    super(props);

    // Declare State
    this.state = {
      longitude:'',
      latitude:''
    };

    // Bind Functions
    this.handleScriptLoad = this.handleScriptLoad.bind(this);
    this.handlePlaceSelect = this.handlePlaceSelect.bind(this);

  }

  handleScriptLoad() {
    // Declare Options For Autocomplete

    var cityBounds = new google.maps.LatLngBounds(
        new google.maps.LatLng(57.8049599, 11.2337249),
        new google.maps.LatLng(57.4451298,12.6223976));

    var options = {
      types: ['address'],
      bounds: cityBounds,
      language: ['se'],
      componentRestrictions: {country: "se"},
      radius: 500
    };


    // Initialize Google Autocomplete
    /*global google*/ // To disable any eslint 'google not defined' errors
    this.autocomplete = new google.maps.places.Autocomplete(
      document.getElementById('autocomplete'),
      options,
    );

    // Fire Event when a suggested name is selected
    this.autocomplete.addListener('place_changed', this.handlePlaceSelect);
  }
  
  handlePlaceSelect() {

    // Extract City From Address Object
    let addressObject = this.autocomplete.getPlace();
    let address = addressObject.address_components;

    var latitude = addressObject.geometry.location.lat()
    var longitude = addressObject.geometry.location.lng()
   
    // Check if address is valid
    if (address) {
      // Set State
      this.setState(
        {
          longitude: longitude,
          latitude: latitude,
        }
      );
      var coordinate = {
        latitude: latitude,
        longitude: longitude
      }
      this.props.onSelectAddress(coordinate)
    }
  }
  

  render() {
    return (
      <div>
        <Script
          url="https://maps.googleapis.com/maps/api/js?key=AIzaSyA1h8qnNHfImevvuRqNorH0xYY1zV4wC6Q&libraries=places"
          onLoad={this.handleScriptLoad}
        />
        <TextField id="autocomplete" placeholder="" label="Bostadens address" fullWidth

        />
      </div>
    );
  }
}

export default AddressSearch;