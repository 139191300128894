import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import AddressSearch from './Addresslookup'



class PredictionInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      observation: {
        suggested_price: '',
        number_of_rooms: '',
        livingm2: '',
        monthlyfee: '',
        latitude: '',
        longitude: '',
        date_sold: ''
      },
      prediction: {},
    };
    

  }
  render() {
    return (

      <React.Fragment>
        <Typography variant="h6" gutterBottom>
          Ange Uppgifter för bostaden
      </Typography>
        <Grid container spacing={24}>
          <Grid item xs={12} md={12}>
            <AddressSearch onSelectAddress={this.props.setLonLat} />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField required name="number_of_rooms" label="Antal rum" fullWidth
            error={isNaN(this.props.observation.number_of_rooms)}
              onChange={this.props.handlePredictionInputChange} />
            <TextField required name="livingm2" error={isNaN(this.props.observation.livingm2)} label="Boyta" helperText="Kvadratmeter (m^2)" fullWidth
              onChange={this.props.handlePredictionInputChange} />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField required name="suggested_price" label="Begärt Pris" fullWidth
            error={isNaN(this.props.observation.suggested_price)}
              onChange={this.props.handlePredictionInputChange} />
            <TextField required name="monthlyfee" label="Månadsavgift" fullWidth
            error={isNaN(this.props.observation.monthlyfee)}
              onChange={this.props.handlePredictionInputChange} />
            <TextField required name="date_sold" type="date" helperText="Försäljningsdatum" fullWidth
              onChange={this.props.handlePredictionInputChange} />
          </Grid>
        </Grid>
      </React.Fragment>


    )
  }
}
export default PredictionInput;